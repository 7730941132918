import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, ref, reactive } from 'vue';
import { format } from 'date-fns';
import { ApolloError } from '@apollo/client/core';
import { SButton, SDatePicker, SModal } from '@simmons/components';

import useVuelidate from '@vuelidate/core';
import { getFilterDatesValidation } from '@/utils/validators';

import {
  SortOrder,
  type SortOrderInput,
  type StatusFieldsWhereInput,
} from '@/generated/shared-graphql';

import {
  getEndDate,
  getGraphQLErrorMessages,
  getStartDate,
} from '@/utils';
import getXlsxFromJson from '@/utils/getXlsxFromJson';
import mapDataToJSON from './mapDataToJson';

import { useLazyExportStatuses, useSnackbar } from '@/composables';

import type { FilterValueType } from '@/views/manage-status/ManageStatusTable.types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportTable',
  props: {
  filterValues: {
    type: Object as PropType<FilterValueType>,
    required: true,
  },
  appliedFilters: {
    type: Object as PropType<Record<keyof FilterValueType, boolean>>,
    required: true,
  },
  sortBy: {
    type: Object as PropType<{ [key: string]: SortOrder | SortOrderInput; } | undefined>,
    default: undefined,
  },
},
  emits: ["update:filterDatesValidation"],
  setup(__props, { emit: __emit }) {

const props = __props;

const isLoading = ref(false);

const showDateModal = ref(false);
const snackbar = useSnackbar();
const { fetchStatuses } = useLazyExportStatuses();

const emit = __emit;

const dates = reactive<{ from: Date | null, to: Date | null; }>({
  from: null,
  to: null,
});

const rules = {
  ...getFilterDatesValidation,
};

const validation = useVuelidate(rules, dates);

const fromDate = computed({
  get: () => {
    if (dates.from !== null) {
      return dates.from;
    }

    // If dates.from is null, fallback to a default value
    return new Date();
  },
  set: (v) => {
    dates.from = v;

    emit('update:filterDatesValidation', validation.value.$invalid);
  },
});

const toDate = computed({
  get: () => {
    if (dates.to !== null) {
      return dates.to;
    }

    // If dates.to is null, fallback to a default value
    return new Date();
  },
  set: (v) => {
    dates.to = v;

    emit('update:filterDatesValidation', validation.value.$invalid);
  },
});

function getWhereStatement(): StatusFieldsWhereInput {
  const { appliedFilters, filterValues } = props;

  // Initializing the statement with fromDate filter
  let statement: StatusFieldsWhereInput = {
    date: {
      gt: fromDate.value,
    },
  };

  // Handle toDate filter
  if (toDate.value) {
    if (!statement.OR) statement.OR = [];
    statement.OR.concat({ publishDate: { lt: toDate.value } });
  }

  // Handle published filter
  if (appliedFilters.published) {
    if (filterValues.published) {
      statement = {
        ...statement,
        published: { equals: filterValues.published === 'published' },
      };
    } else {
      appliedFilters.published = false;
    }
  }

  // Handle date filter if applied
  if (appliedFilters.date) {
    if (filterValues.date) {
      statement = {
        ...statement,
        date: {
          gt: getStartDate(filterValues.date.from),
          lt: getEndDate(filterValues.date.to),
        },
      };
    } else {
      appliedFilters.date = false;
    }
  }

  // Handle publishDate filter if applied
  if (appliedFilters.publishDate) {
    if (filterValues.publishDate) {
      if (!statement.OR) statement.OR = [];
      statement.OR.concat({
        publishDate: {
          gt: getStartDate(filterValues.publishDate.from),
          lt: getEndDate(filterValues.publishDate.to),
        },
      });
    }
  }

  if (appliedFilters.jurisdiction) {
    if (filterValues.jurisdiction.length > 0) {
      statement = {
        ...statement,
        locations: {
          some: {
            id: {
              in: filterValues.jurisdiction,
            },
          },
        },
      };
    } else {
      appliedFilters.jurisdiction = false;
    }
  }

  return statement;
}

function handleExportClick(): void {
  showDateModal.value = true;
}

const handleClick = async () => {
  isLoading.value = true;

  try {
    const r = await fetchStatuses({
      where: getWhereStatement(),
      orderBy: props.sortBy || { date: { sort: SortOrder.Desc } },
    });

    if (!r?.data || r.data.statusFieldsesConnection.edges.length === 0) {
      snackbar.danger({ messageContent: 'No data found for the selected filters.' });
      isLoading.value = false;
      return;
    }

    const data = mapDataToJSON(r?.data);
    const timestamp = format(new Date(), 'dd-MM-yyyy');
    const filename = `Status export ${timestamp}.xlsx`;

    if (data) {
      getXlsxFromJson(data, filename);
    }
  } catch (e) {
    const errors = getGraphQLErrorMessages(e as ApolloError);
    errors.forEach((error) => snackbar.danger({ messageContent: error }));
  } finally {
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(SButton), { onClick: handleExportClick }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" Export ")
      ])),
      _: 1
    }),
    _createVNode(_unref(SModal), {
      "show-modal": showDateModal.value,
      width: 25,
      height: "auto",
      onToggle: _cache[3] || (_cache[3] = ($event: any) => (showDateModal.value = false))
    }, {
      header: _withCtx(() => _cache[5] || (_cache[5] = [
        _createElementVNode("h3", null, "Please select date range for export:", -1)
      ])),
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Start Date:", -1)),
        _createVNode(_unref(SDatePicker), {
          modelValue: fromDate.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((fromDate).value = $event)),
          target: "body",
          name: "from",
          validation: _unref(validation)
        }, null, 8, ["modelValue", "validation"]),
        _cache[7] || (_cache[7] = _createElementVNode("strong", null, "End Date:", -1)),
        _createVNode(_unref(SDatePicker), {
          modelValue: toDate.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((toDate).value = $event)),
          target: "body",
          name: "to",
          validation: _unref(validation)
        }, null, 8, ["modelValue", "validation"])
      ]),
      footer: _withCtx(() => [
        _createVNode(_unref(SButton), {
          loading: isLoading.value,
          type: "primary",
          class: "u-mr-sm",
          onClick: handleClick
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode(" Export ")
          ])),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_unref(SButton), {
          type: "secondary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (showDateModal.value = false))
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" Cancel ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show-modal"])
  ]))
}
}

})
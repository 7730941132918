<template>
  <s-editor
    class="generic-editor"
    :editor-bundle="genericRichTextEditorBundle"
    :config="editorConfig"
    @blur="handleBlur"
    @ready="handleEditorReady"
  />
</template>

<script lang="ts" setup>
import type ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import {
  SEditor,
} from '@simmons/components';
import DOMPurify from 'dompurify';
import { computed } from 'vue';

import enableEditorDevTools from '@/utils/enableEditorDevTools';
import sanitizeEditorContent from '@/utils/sanitizeEditorContent';
import genericRichTextEditorBundle from '../../../editor/src/genericRichTextEditor/ckeditor';
import { getEditorConfig } from '../../../editor/src/genericRichTextEditor/config';

const props = defineProps({
  initialValue: {
    type: String,
    default: '',
  },
});

const emit = defineEmits<{
  'on-change': [value: string];
}>();

const editorConfig = computed(
  () => getEditorConfig({ initialEditorData: DOMPurify.sanitize(props.initialValue) }),
);

function setupEditorPlugins(editor: ClassicEditor): void {
  if (editor.plugins.has('TrackChangesUI')) {
    editor.execute('trackChanges');
  }
}

async function handleEditorReady(event: { id: string, editor: ClassicEditor; }) {
  const { editor } = event;

  await enableEditorDevTools(editor);

  setupEditorPlugins(editor);
}

function handleBlur(event: { id: string, editor: ClassicEditor; }) {
  const { editor } = event;
  const data = editor.getData();

  emit('on-change', sanitizeEditorContent(data));
}
</script>

<style lang="scss">
.generic-editor+.ck-editor {

  ul,
  ol {
    padding: 0 0 0 20px;
    margin: 8px 0;
  }
}
</style>

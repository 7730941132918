import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot } from "vue"

import type { PropType } from 'vue';
import { toRefs } from 'vue';

import { useMutatingIndicator } from '@/composables';
import type { MutatingIndicatorType } from '@/composables/mutating-indicators';


export default /*@__PURE__*/_defineComponent({
  __name: 'MutatingIndicator',
  props: {
  type: {
    type: String as PropType<MutatingIndicatorType>,
    required: true,
  },
  entityId: {
    type: String,
    required: true,
  },
  updatedAt: {
    type: Date,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { entityId, type, updatedAt } = toRefs(props);
const { isMutating, hasError } = useMutatingIndicator(type, entityId, updatedAt);

return (_ctx: any,_cache: any) => {
  return _renderSlot(_ctx.$slots, "default", {
    isMutating: _unref(isMutating),
    hasError: _unref(hasError)
  })
}
}

})
<!-- eslint-disable vue/no-v-html -->
<template>
  <slot :sanitised-html="sanitisedHtml">
    <component
      :is="tag"
      :inner-html="sanitisedHtml"
    />
  </slot>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import DOMPurify from 'dompurify';

const props = defineProps({
  html: {
    type: String,
    default: '',
  },
  tag: {
    type: String,
    default: 'div',
  },
});

const sanitisedHtml = computed(() => DOMPurify.sanitize(props.html));
</script>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { SAlert } from '@simmons/components';
import isChrome from '@/utils/isChrome';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecommendedBrowserBanner',
  setup(__props) {

const ranInChrome = isChrome();

return (_ctx: any,_cache: any) => {
  return (!_unref(ranInChrome))
    ? (_openBlock(), _createBlock(_unref(SAlert), {
        key: 0,
        class: "recommended-browser-banner",
        description: "\n      The application is not supported in this browser\n      and any changes to content may not be saved,\n      please close this browser and open in Google Chrome\n    ",
        type: "error"
      }))
    : _createCommentVNode("", true)
}
}

})
<template>
  <slot
    :options="options"
    :loading="loading"
  />
</template>

<script lang="ts" setup>
import {
  computed,
  watch,
  type PropType,
} from 'vue';

import type {
  AsyncFunc,
  Options,
  OptionsGetterAsyncProp,
} from './OptionsGetter.types';

const props = defineProps({
  options: {
    type: Array as PropType<Options>,
    required: false,
    default: () => [],
  },
  async: {
    type: Object as PropType<OptionsGetterAsyncProp>,
    required: false,
    default: null,
  },
});

// We can't use computed function here
// 'cause Vue starts throwing us warnings in the runtime
// with something with incorrect usage of onBeforeUnmount events...
// IDK why it happens, so a solution is just to do almost the same
// but in `watch` function
let optionsGetter: ReturnType<AsyncFunc> | null = null;

watch(() => props.async, (asyncValue) => {
  if (asyncValue) {
    optionsGetter = asyncValue.func();
  } else {
    optionsGetter = null;
  }
}, { immediate: true });

const data = computed(() => (optionsGetter ? optionsGetter.data.value : null));
const loading = computed<boolean>(() => (optionsGetter ? optionsGetter.loading.value : false));

const options = computed(() => {
  if (props.async && data.value) {
    return props.async.mapper(data.value);
  }

  return props.options || [];
});
</script>

<template>
  <s-input-dropdown
    class="product__dropdown"
    :dark-bg="true"
    :model-value="currentlySelectedProduct"
    :options="availableProducts"
    @update:model-value="handleChangeProduct"
  />
</template>

<script lang="ts" setup>
import { computed, watchEffect, type Ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  SInputDropdown,
} from '@simmons/components';

import {
  APP__SET_SELECTED_PRODUCT,
  APP__SET_CLIENT_ENDPOINT,
} from '@/store/modules/app/mutations';
import { LOCAL_STORAGE_SELECTED_PRODUCT_KEY } from '@/config/constants';
import type { ActiveProduct } from '@/config';

import { getRouteNames, isActiveProduct } from '@/utils';
import { setItemInLocalStorage } from '@/utils/localStorage';
import { setItemInState } from '@/utils/vuexState';

import useUserAccess from '@/composables/useUserAccess';
import useSelectedProduct from '@/composables/useSelectedProduct';
import type { PrimitiveValue, PrimitiveValueVec } from '@/types/utilityTypes';

const router = useRouter();

const {
  selectedProduct: currentSelectedProduct,
  routeProduct,
  productEndpoint,
} = useSelectedProduct();

const { productsUserCanAccess = [] } = (currentSelectedProduct.value !== null)
  ? useUserAccess(currentSelectedProduct as Ref<ActiveProduct>)
  : {};

const currentlySelectedProduct = computed(
  (): ActiveProduct | undefined => {
    const value = currentSelectedProduct.value || routeProduct.value;

    if (value) {
      return value;
    }

    return undefined;
  },
);
const availableProducts = computed(
  () => productsUserCanAccess.filter(
    ({ value }) => isActiveProduct(value),
  ),
);

function setSelectedProductInState(selectedProduct: string) {
  setItemInState<string>(APP__SET_SELECTED_PRODUCT, selectedProduct);
}

function setSelectedProductInLocalStorage(selectedProduct: ActiveProduct) {
  setItemInLocalStorage<string>(
    LOCAL_STORAGE_SELECTED_PRODUCT_KEY,
    selectedProduct,
  );
}

watchEffect(() => {
  const product = currentlySelectedProduct.value;

  if (product && currentSelectedProduct.value !== product) {
    setSelectedProductInState(product);
    setSelectedProductInLocalStorage(product);
  }
});

const stopSetClientState = watchEffect(() => {
  setItemInState<string>(APP__SET_CLIENT_ENDPOINT, productEndpoint.value);
});

function pushToRoute(routeName: string) {
  router.push({
    name: routeName,
  });
}

function handleChangeProduct(productValue: PrimitiveValue | PrimitiveValueVec | null) {
  stopSetClientState();

  if (productValue && typeof productValue === 'string' && isActiveProduct(productValue)) {
    const routeNames = getRouteNames(router.getRoutes());
    const existingProductRoute = routeNames.find(
      (routeName) => (routeName === productValue),
    );

    setSelectedProductInState(productValue);
    setSelectedProductInLocalStorage(productValue);

    if (existingProductRoute) {
      pushToRoute(existingProductRoute);
      setItemInState<string>(APP__SET_CLIENT_ENDPOINT, productEndpoint.value);
    }
  }
}
</script>

<style lang="scss">
.product__dropdown.outlined {
  .s-input-dropdown__box {
    border-color: rgba(var(--colour-border-default), 0.3) !important;
  }
}
</style>

<template>
  <s-sidebar
    :dark="darkMode"
    :width="sidebarWidth"
  >
    <s-menu>
      <s-menu-item
        v-for="menuLink in menuLinks"
        :key="menuLink.key"
        :menu-key="menuLink.key"
        :icon-text="menuLink.label.slice(0, 2).toUpperCase()"
        :active="true"
      >
        <template
          v-if="menuLink.icon"
          #icon
        >
          <router-link
            :to="{ name: menuLink.routeName }"
            :class="{ 'white-link': darkMode }"
          >
            <component :is="menuLink.icon" />
          </router-link>
        </template>
        <router-link
          :to="{ name: menuLink.routeName }"
          :class="{ 'white-link': darkMode }"
        >
          <span class="menuItem">
            {{ menuLink.label }}
          </span>
        </router-link>
      </s-menu-item>
    </s-menu>
  </s-sidebar>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue';

import {
  SMenu, SMenuItem, SSidebar,
} from '@simmons/components';
import useUserAccess from '@/composables/useUserAccess';
import useSelectedProduct from '@/composables/useSelectedProduct';
import { useStore } from '@/store';

import { APP__DARK_MODE } from '@/store/modules/app/getters';

import { routePagesInMenu, menuRouteLabels } from '@/config/constants';
import {
  isInArray,
  filterRoutesByProductName,
  getViewFromRoute,
} from '@/utils';

import type { ActiveProduct } from '@/config';
import type { ElementOf } from '@/types/utilityTypes';
import type { RouteRecordComposite } from '@/types/vueRouter';

type MenuLink = {
  routeName: string;
  key: string;
  icon: string | unknown;
  label: string;
};

defineProps({
  sidebarWidth: {
    type: String,
    required: false,
    default: '250px',
  },
});

const { useGetter } = useStore();
const { selectedProduct } = useSelectedProduct();

const darkMode = computed((): boolean => useGetter(APP__DARK_MODE));

const mapMenuLink = (routeRecord: RouteRecordComposite): MenuLink => ({
  routeName: routeRecord.name as string,
  key: routeRecord.name as string,
  icon: routeRecord.meta?.icon,
  label: menuRouteLabels[getViewFromRoute(routeRecord) as ElementOf<typeof routePagesInMenu>],
});

function getMenuLinks(currentProduct: ComputedRef<ActiveProduct>): MenuLink[] {
  const { routesUserCanAccess } = useUserAccess(currentProduct);
  const validMenuRoutes = routesUserCanAccess.filter((routeRecord) => {
    const routeViewName = getViewFromRoute(routeRecord);

    return (routeViewName && isInArray(routeViewName, routePagesInMenu));
  });
  const validProductRoutes: RouteRecordComposite[] = filterRoutesByProductName(
    currentProduct.value,
  )(validMenuRoutes);

  return validProductRoutes.map(mapMenuLink);
}

const menuLinks = computed(() => (selectedProduct.value
  ? getMenuLinks(selectedProduct as ComputedRef<ActiveProduct>)
  : []
));
</script>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "unpublish-loader-container"
}
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-footer" }

import { SButton, SLoader, SModal } from '@simmons/components';
import { Cancel as CancelIcon } from '@simmons/components/icons';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UnpublishButton',
  props: {
  headerContent: {
    type: String,
    required: true,
  },
  actionButtonMessage: {
    type: String,
    default: 'Yes, unpublish now',
  },
  cancelButtonMessage: {
    type: String,
    default: 'Cancel',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
},
  emits: ["on-submit", "on-cancel"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const showModal = ref(false);

function closeModal() {
  showModal.value = false;
}

function handleUnpublishButtonClick() {
  if (!props.disabled) {
    showModal.value = true;
  }
}

function handleModalToggle() {
  showModal.value = !showModal.value;
}

function handleCancel() {
  closeModal();

  emit('on-cancel');
}

function handleSubmit() {
  closeModal();

  emit('on-submit');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(CancelIcon), _mergeProps(_ctx.$attrs, {
      class: ["unpublish-button", {
      'unpublish-button--disabled': __props.disabled,
    }],
      width: "18",
      height: "18",
      role: "button",
      title: "Unpublish",
      onClick: handleUnpublishButtonClick
    }), null, 16, ["class"]),
    (__props.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(SLoader), {
            class: "unpublish-loader",
            loading: "",
            height: 16
          })
        ]))
      : _createCommentVNode("", true),
    (showModal.value)
      ? (_openBlock(), _createBlock(_unref(SModal), {
          key: 1,
          "show-modal": "",
          width: "auto",
          height: "auto",
          target: "body",
          onToggle: handleModalToggle
        }, {
          header: _withCtx(() => [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(__props.headerContent), 1)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(SButton), {
                type: "primary",
                onClick: handleSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.actionButtonMessage), 1)
                ]),
                _: 1
              }),
              _createVNode(_unref(SButton), {
                type: "secondary",
                onClick: handleCancel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.cancelButtonMessage), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
<template>
  <div class="app-container">
    <recommended-browser-banner />
    <div
      v-if="!isAuthPage"
      class="content"
    >
      <app-layout>
        <template #appSidebar>
          <app-sidebar
            class="menu"
            :sidebar-width="sidebarWidth"
            @mouseenter="handleToggleWidth"
            @mouseleave="handleToggleWidth"
          />
        </template>
        <template #appMainContent>
          <main class="main">
            <branding-header @on-sidebar-open="handleSidebarOpen" />
            <div class="s-row">
              <div class="s-col s-col-12 main-content">
                <s-snackbar-container
                  :snackbars-data-arr="snackbars"
                  :position="'top'"
                  :width="20"
                  @pop-from-queue="removeSnackbar"
                />
                <router-view />
              </div>
            </div>
          </main>
        </template>
      </app-layout>
    </div>
    <router-view v-else />
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  ref,
  type Ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { SSnackbarContainer } from '@simmons/components';
import type { ValueOf } from 'ts-essentials';

import AppLayout from '@/layout/AppLayout.vue';
import AppSidebar from '@/layout/AppSidebar.vue';
import BrandingHeader from '@/layout/components/branding-header';
import { RecommendedBrowserBanner } from '@/components';

import { useStore } from '@/store';
import useAppEventListeners from '@/composables/useAppEventListeners';
import { Views } from '@/router/router.config';
import { sidebarWidths } from '@/config/constants';

import {
  APP__SNACKBAR,
  getters,
} from './store/modules/app/getters';
import { APP__REMOVE_SNACKBAR } from './store/modules/app/mutations';

const route = useRoute();
const { useMutation, useGetter } = useStore();
useAppEventListeners();

const isSidebarHovering = ref(false);
const sidebarWidth: Ref<ValueOf<typeof sidebarWidths>> = ref(sidebarWidths.opened);

const snackbars = computed(() => (
  useGetter<ReturnType<typeof getters[typeof APP__SNACKBAR]>>(APP__SNACKBAR)
));

const isAuthPage = computed(() => {
  const { name: routeName } = route;

  return (!routeName || (routeName && routeName === Views.AUTHENTICATION));
});

function handleSidebarOpen(opened: boolean): void {
  sidebarWidth.value = opened ? sidebarWidths.opened : sidebarWidths.closed;
}

function handleToggleWidth(): void {
  if (sidebarWidth.value !== sidebarWidths.closed) {
    isSidebarHovering.value = !isSidebarHovering.value;
    sidebarWidth.value = isSidebarHovering.value ? sidebarWidths.hovered : sidebarWidths.opened;
  }
}

function removeSnackbar(id: string): void {
  useMutation(APP__REMOVE_SNACKBAR, id);
}
</script>

<style lang="scss" scoped>
.app-container {
  position: relative;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  flex: 1;
}
</style>

<style lang="scss">
@import "assets/styles/main.scss";
@import 'vue-json-pretty/lib/styles.css';

.main {
  width: 100%;
  height: 100%;
}

.main-content {
  width: 100%;
  margin-left: v-bind(sidebarWidth);
  padding-left: 20px;
  transition: margin-left 0.5s ease;
}

// TODO: update styles for dark mode in component lib
.s-menu__item {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.s-menu__item-content {
  width: 100%;
}

.white-link,
.white-link:link,
.white-link:visited {
  display: flex;
  color: rgb(var(--colour-white));
  padding: 1rem 2rem;
}

.white-link:hover,
.white-link:active,
.s-menu__item:hover .white-link,
.s-menu__item:active .white-link {
  color: rgb(var(--colour-primary));
}

.menu {
  transition: width 0.5s ease;

  li {
    font-size: 18px;
    align-items: center;

    .icon {
      margin-top: 5px;
    }
  }

  &Item {
    margin-left: -300px;
    transition: margin-left 0.5s ease;
  }
}

.menu:hover {
  .menuItem {
    margin-left: -20px;
  }
}
</style>

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": "2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    title: _ctx.title
  }, _cache[0] || (_cache[0] = [
    _createStaticVNode("<line x1=\"2\" x2=\"5\" y1=\"12\" y2=\"12\"></line><line x1=\"19\" x2=\"22\" y1=\"12\" y2=\"12\"></line><line x1=\"12\" x2=\"12\" y1=\"2\" y2=\"5\"></line><line x1=\"12\" x2=\"12\" y1=\"19\" y2=\"22\"></line><circle cx=\"12\" cy=\"12\" r=\"7\"></circle>", 5)
  ]), 8, _hoisted_1))
}
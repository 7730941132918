<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21" />
    <line
      x1="9"
      y1="3"
      x2="9"
      y2="18"
    />
    <line
      x1="15"
      y1="6"
      x2="15"
      y2="21"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LegendIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Legend icon',
    },
  },
});
</script>

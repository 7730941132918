<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    :title="title"
  >
    <path
      d="M15.5 2H8.6c-.4 0-.8.2-1.1.5-.3.3-.5.7-.5 1.1v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8c.4
    0 .8-.2 1.1-.5.3-.3.5-.7.5-1.1V6.5L15.5 2z"
    />
    <path d="M3 7.6v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8" />
    <path d="M15 2v5h5" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ManageUpdateCycleIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Manage update cycle icon',
    },
  },
});
</script>

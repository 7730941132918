<template>
  <slot
    :is-mutating="isMutating"
    :has-error="hasError"
  />
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { toRefs } from 'vue';

import { useMutatingIndicator } from '@/composables';
import type { MutatingIndicatorType } from '@/composables/mutating-indicators';

const props = defineProps({
  type: {
    type: String as PropType<MutatingIndicatorType>,
    required: true,
  },
  entityId: {
    type: String,
    required: true,
  },
  updatedAt: {
    type: Date,
    required: true,
  },
});

const { entityId, type, updatedAt } = toRefs(props);
const { isMutating, hasError } = useMutatingIndicator(type, entityId, updatedAt);
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    :title="title"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
    />
    <circle
      cx="12"
      cy="12"
      r="6"
    />
    <circle
      cx="12"
      cy="12"
      r="2"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EeaDefinitionIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'EEA definition icon',
    },
  },
});
</script>

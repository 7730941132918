import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "options" }

import {
  PropType, watch, onMounted, ref,
} from 'vue';
import { SButton, SModal } from '@simmons/components';
import { Warning } from '@simmons/components/icons';
import graphqlFetch from '@/services/graphqlFetch';
import CycleDocumentsProcessingOrFailed
  from '../../views/update-cycle/graphql/query/CycleDocumentsProcessingOrFailed.gql';
import LastPublishedQuery
  from '../../views/update-cycle/graphql/query/CycleDocumentLastPublished.gql';
import type {
  LastPublishedVariables,
  LastPublishedResult,
  FailedCycleVariables,
  FailedCycleResult,
} from './confirmationModal.types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmationModal',
  props: {
  header: {
    type: String,
    required: true,
  },
  show: {
    type: Boolean,
    default: false,
  },
  cycleIds: {
    type: Array as PropType<Array<string>>,
    default: () => [],
  },
},
  emits: ["on-cancel", "on-submit", "on-loaded", "update-show"],
  setup(__props, { emit: __emit }) {


const props = __props;

const docCyclesNumber = ref(0);
const compCycleIds = ref(props.cycleIds);
const loading = ref(true);

onMounted(async () => {
  if (compCycleIds.value.length) {
    const foundLastPublished = await graphqlFetch<LastPublishedResult, LastPublishedVariables>(
      LastPublishedQuery, { documentCycleIds: compCycleIds.value },
    );
    const lastPublished = foundLastPublished.data?.documentCycles?.at(0);
    const failedCycles = await graphqlFetch<FailedCycleResult, FailedCycleVariables>(
      CycleDocumentsProcessingOrFailed,
      {
        lastPublished: lastPublished?.updatedAt,
        documentId: lastPublished?.documentId ?? '0',
      },
    );

    docCyclesNumber.value = failedCycles.data?.documentCycles.length ?? 0;
    loading.value = false;
  } else {
    loading.value = false;
  }
});

const emit = __emit;

watch(docCyclesNumber, (after, before) => {
  if ((after !== before) && !loading.value) {
    emit('on-loaded');
  }
});

return (_ctx: any,_cache: any) => {
  return (!loading.value)
    ? (_openBlock(), _createBlock(_unref(SModal), {
        key: 0,
        style: {"text-align":"center"},
        "show-modal": __props.show,
        width: 30,
        height: "auto",
        onToggle: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update-show', false)))
      }, {
        header: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(__props.header), 1)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(SButton), {
              type: "primary",
              class: "u-mr-sm",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-submit')))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Confirm ")
              ])),
              _: 1
            }),
            _createVNode(_unref(SButton), {
              type: "secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-cancel')))
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Cancel ")
              ])),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          (docCyclesNumber.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", null, [
                  _createVNode(_unref(Warning))
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                  _createElementVNode("p", null, [
                    _createElementVNode("b", null, " Warning: There are documents currently being processed or have failed to publish for these jurisdictions! ")
                  ]),
                  _createElementVNode("br"),
                  _createElementVNode("u", null, " Do you still want to proceed with the publication? ")
                ], -1))
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show-modal"]))
    : _createCommentVNode("", true)
}
}

})
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 -960 960 960"
    width="1em"
    fill="currentColor"
    :title="title"
  >
    <path
      d="m648-140 112-112v92h40v-160H640v40h92L620-168l28 28Zm-448 20q-33
      0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5
      23.5T840-760v268q-19-9-39-15.5t-41-9.5v-243H200v560h242q3 22
      9.5 42t15.5 38H200Zm0-120v40-560 243-3 280Zm80-40h163q3-21
      9.5-41t14.5-39H280v80Zm0-160h244q32-30
      71.5-50t84.5-27v-3H280v80Zm0-160h400v-80H280v80ZM720-40q-83 0-141.5-58.5T520-240q0-83
      58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Z"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DataExportIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Data export icon',
    },
  },
});
</script>

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "date"
}
const _hoisted_2 = {
  class: "date-edit-button",
  title: "Edit date"
}
const _hoisted_3 = {
  key: 1,
  class: "date-editor"
}
const _hoisted_4 = { class: "date-editor-end" }

import { SDatePicker, SLoader } from '@simmons/components';
import { PencilEdit, Save as SaveIcon } from '@simmons/components/icons';
import { onClickOutside } from '@vueuse/core';
import type { PropType } from 'vue';
import { ref } from 'vue';

import useSnackbar from '@/composables/useSnackbar';


export default /*@__PURE__*/_defineComponent({
  __name: 'DateEditor',
  props: {
  date: {
    type: Date,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
    required: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
    required: false,
  },
  isSubmitting: {
    type: Boolean,
    default: false,
    required: false,
  },
  availableDateFunc: {
    type: Function as PropType<(date: Date) => boolean>,
    default: null,
    required: false,
  },
},
  emits: ["update:is-editing", "on-submit", "update:model-value"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const container = ref<HTMLElement>();
const newDate = ref<Date>(props.date);

const snackbar = useSnackbar();

onClickOutside(container, () => {
  emit('update:is-editing', false);
});

function handleEditClick(): void {
  newDate.value = props.date;

  emit('update:is-editing', true);
}

function handleSubmit(): void {
  const isAvailable = props.availableDateFunc?.(newDate.value) ?? true;

  if (props.isSubmitting) {
    return;
  }

  if (!isAvailable) {
    snackbar.danger({ messageContent: "Selected date isn't available." });
    return;
  }

  emit('on-submit', newDate.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "container",
    ref: container
  }, [
    (!__props.readonly)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!__props.isEditing)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "date", { date: __props.date }, () => [
                  _createElementVNode("span", null, _toDisplayString(__props.date), 1)
                ]),
                _createElementVNode("span", _hoisted_2, [
                  _createVNode(_unref(PencilEdit), { onClick: handleEditClick })
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_unref(SDatePicker), {
                  modelValue: newDate.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newDate).value = $event)),
                  name: "date-editor",
                  "available-date-func": __props.availableDateFunc
                }, null, 8, ["modelValue", "available-date-func"]),
                _createElementVNode("div", _hoisted_4, [
                  (__props.isSubmitting)
                    ? (_openBlock(), _createBlock(_unref(SLoader), {
                        key: 0,
                        class: "date-editor-submitting",
                        loading: "",
                        width: 16,
                        height: 16
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass(["date-editor-save-button", {
              'date-editor-save-button--disabled': __props.isSubmitting,
            }])
                  }, [
                    _createVNode(_unref(SaveIcon), { onClick: handleSubmit })
                  ], 2)
                ])
              ]))
        ], 64))
      : _renderSlot(_ctx.$slots, "date", {
          key: 1,
          date: __props.date
        }, () => [
          _createElementVNode("span", null, _toDisplayString(__props.date), 1)
        ])
  ], 512))
}
}

})
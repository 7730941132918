import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1em",
  height: "1em",
  viewBox: "0 0 24 24",
  fill: "none",
  stroke: "currentColor",
  "stroke-width": "2",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("polygon", { points: "3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21" }, null, -1),
    _createElementVNode("line", {
      x1: "9",
      y1: "3",
      x2: "9",
      y2: "18"
    }, null, -1),
    _createElementVNode("line", {
      x1: "15",
      y1: "6",
      x2: "15",
      y2: "21"
    }, null, -1)
  ])))
}
import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = {
  key: 0,
  class: "content"
}
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "s-row" }
const _hoisted_5 = { class: "s-col s-col-12 main-content" }

import {
  computed,
  ref,
  type Ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { SSnackbarContainer } from '@simmons/components';
import type { ValueOf } from 'ts-essentials';

import AppLayout from '@/layout/AppLayout.vue';
import AppSidebar from '@/layout/AppSidebar.vue';
import BrandingHeader from '@/layout/components/branding-header';
import { RecommendedBrowserBanner } from '@/components';

import { useStore } from '@/store';
import useAppEventListeners from '@/composables/useAppEventListeners';
import { Views } from '@/router/router.config';
import { sidebarWidths } from '@/config/constants';

import {
  APP__SNACKBAR,
  getters,
} from './store/modules/app/getters';
import { APP__REMOVE_SNACKBAR } from './store/modules/app/mutations';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

_useCssVars(_ctx => ({
  "8ddc42de": (sidebarWidth.value)
}))

const route = useRoute();
const { useMutation, useGetter } = useStore();
useAppEventListeners();

const isSidebarHovering = ref(false);
const sidebarWidth: Ref<ValueOf<typeof sidebarWidths>> = ref(sidebarWidths.opened);

const snackbars = computed(() => (
  useGetter<ReturnType<typeof getters[typeof APP__SNACKBAR]>>(APP__SNACKBAR)
));

const isAuthPage = computed(() => {
  const { name: routeName } = route;

  return (!routeName || (routeName && routeName === Views.AUTHENTICATION));
});

function handleSidebarOpen(opened: boolean): void {
  sidebarWidth.value = opened ? sidebarWidths.opened : sidebarWidths.closed;
}

function handleToggleWidth(): void {
  if (sidebarWidth.value !== sidebarWidths.closed) {
    isSidebarHovering.value = !isSidebarHovering.value;
    sidebarWidth.value = isSidebarHovering.value ? sidebarWidths.hovered : sidebarWidths.opened;
  }
}

function removeSnackbar(id: string): void {
  useMutation(APP__REMOVE_SNACKBAR, id);
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(RecommendedBrowserBanner)),
    (!isAuthPage.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(AppLayout, null, {
            appSidebar: _withCtx(() => [
              _createVNode(AppSidebar, {
                class: "menu",
                "sidebar-width": sidebarWidth.value,
                onMouseenter: handleToggleWidth,
                onMouseleave: handleToggleWidth
              }, null, 8, ["sidebar-width"])
            ]),
            appMainContent: _withCtx(() => [
              _createElementVNode("main", _hoisted_3, [
                _createVNode(_unref(BrandingHeader), { onOnSidebarOpen: handleSidebarOpen }),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_unref(SSnackbarContainer), {
                      "snackbars-data-arr": snackbars.value,
                      position: 'top',
                      width: 20,
                      onPopFromQueue: removeSnackbar
                    }, null, 8, ["snackbars-data-arr"]),
                    _createVNode(_component_router_view)
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
  ]))
}
}

})
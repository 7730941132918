import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "s-paginated-table"
}
const _hoisted_2 = { class: "s-paginated-table-loading__mask" }
const _hoisted_3 = {
  key: 0,
  class: "s-paginated-table-pagination__wrapper"
}
const _hoisted_4 = { class: "s-paginated-table-pagination__wrapper_text" }
const _hoisted_5 = { class: "s-paginated-table-pagination__wrapper_right" }
const _hoisted_6 = { key: 1 }

import { isEqual } from 'lodash-es';
import {
  computed,
  reactive,
  ref,
  watch,
  watchEffect,
  type PropType,
  type Ref,
} from 'vue';
import type { DocumentNode } from 'graphql';
import {
  SButton,
  SLoader,
  SPagination,
  STable,
} from '@simmons/components';
import { CircularQuarterRing } from '@simmons/components/icons/spinners';

import type {
  AsyncGqlTableColumn,
  AsyncTableFetchError,
  AsyncTableRefetch,
  FetchOptions,
  OptimisticRow,
  PaginationOptions,
  SortObject,
} from './AsyncGqlTable.types';

import { useProductQuery, useRouteQuery, useSnackbar } from '@/composables';
import { SortOrder, SortOrderInput } from '@/generated/shared-graphql';


export default /*@__PURE__*/_defineComponent({
  __name: 'AsyncGqlTable',
  props: {
  columns: {
    required: true,
    type: Array as PropType<AsyncGqlTableColumn[]>,
  },
  countQuery: {
    required: true,
    type: (Object as PropType<DocumentNode>),
  },
  countResponseMapper: {
    required: true,
    type: Function, // TODO: remove function as prop
  },
  query: {
    required: true,
    type: (Object as PropType<DocumentNode>),
  },
  responseMapper: {
    required: true,
    type: Function, // TODO: remove function as prop
  },
  defaultSort: {
    type: Object as PropType<SortObject>,
    required: false,
    default: () => ({
      key: '',
      isAscending: true,
    }),
  },
  filters: {
    default: null,
    required: false,
    type: Object,
  },
  handleSort: {
    default: null,
    required: false,
    type: (Function as PropType<(sortObj: SortObject) => { // TODO: remove function as prop
      [key: string]: SortOrder | SortOrderInput;
    }>),
  },
  queryWhere: {
    default: () => ({}),
    required: false,
    type: Object,
  },
  stickyHeader: {
    default: false,
    required: false,
    type: Boolean,
  },
  tableClass: {
    default: null,
    required: false,
    type: String,
  },
  ignoreDataErrors: {
    default: false,
    required: false,
    type: Boolean,
  },
  pagination: {
    default: true,
    required: false,
    type: Boolean,
  },
  optimisticRowUpdate: {
    required: false,
    type: Object as PropType<OptimisticRow<ORUF>>,
  },
},
  emits: ["get:tableData", "get:tableDataRefetch", "error:fetch-data", "error:fetch-total", "error:no-data", "on:sort"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

const snackbar = useSnackbar();

const policy = {
  errorPolicy: 'all',
  fetchPolicy: 'cache-and-network',
};
const availableItemsPerPageOptions = [5, 10, 25, 50, 100]; // TODO: move to a prop or as config
const dataLoaded = ref(false);
const isTableLoading = ref(false);
const displayDataError = ref(!props.ignoreDataErrors ?? true);

function resolveSortParam(sortObj: SortObject): {
  [key: string]: SortOrder | SortOrderInput;
} {
  const { key, isAscending } = sortObj;

  if (props.handleSort) {
    return props.handleSort(sortObj);
  }

  const column = props.columns.find((col) => col.name === key);

  if (column?.nullOrdering?.enabled) {
    return {
      [key]: {
        sort: isAscending
          ? SortOrder.Asc : SortOrder.Desc,
        nulls: column?.nullOrdering?.position,
      },
    };
  }

  return isAscending ? { [key]: SortOrder.Asc } : { [key]: SortOrder.Desc };
}

const paginationOptions = reactive<PaginationOptions>({
  itemsPerPage: 10,
  currentPage: 1,
  orderBy: resolveSortParam(props.defaultSort),
});

const { update, get } = useRouteQuery();
const where = computed<FetchOptions['where']>(() => props.queryWhere);
const countVars = computed<FetchOptions>(() => ({
  ...where.value,
}));

const queryVars = computed<FetchOptions>(() => ({
  first: paginationOptions.itemsPerPage,
  skip: (paginationOptions.currentPage - 1) * paginationOptions.itemsPerPage,
  orderBy: paginationOptions.orderBy,
  ...countVars.value,
}));

const {
  result: dataResult,
  loading: dataLoading,
  error: dataError,
  refetch: dataRefetch,
  onError: onDataError,
  onResult: onDataResult,
} = useProductQuery(
  props.query,
  queryVars,
  policy,
);

const {
  result: totalResult,
  loading: totalLoading,
  error: totalError,
  refetch: totalRefetch,
  onError: onTotalError,
} = useProductQuery(
  props.countQuery,
  countVars,
  policy,
);

const tableData = computed(() => (props.responseMapper(dataResult.value)));
const totalItems = computed((): number => (props.countResponseMapper(totalResult.value)));

watch(() => props.optimisticRowUpdate, (row, prev) => {
  // find the row in the table data and update it
  if (row && (!prev || row.value !== prev.value)) {
    const index = tableData.value.findIndex((r: any) => r.id === row.id);

    if (index !== -1) {
      tableData.value[index][row.field] = row.value;
    }
  }
});

watchEffect(() => {
  if (paginationOptions.orderBy) {
    const orderByKey = Object.keys(paginationOptions.orderBy)[0] || '';

    if (orderByKey) emit('on:sort', orderByKey);
  }
});

// watch the total items and emit an event related to whether or not there are any items
watchEffect(() => {
  const total = totalItems.value;

  emit('error:no-data', (total === 0));
});

watch(dataLoading, (isLoading: boolean) => {
  if (!isLoading) {
    emit('get:tableData', tableData);
    emit('get:tableDataRefetch', dataRefetch);
    dataLoaded.value = true;
  }
});

const paginationText = computed(() => {
  const skip = queryVars.value.skip || 0;
  const fromValue = (skip + 1) < totalItems.value
    ? skip + 1
    : totalItems.value;
  const toValue = (skip + tableData.value.length) < totalItems.value
    ? skip + tableData.value.length
    : totalItems.value;

  return `Showing ${tableData.value.length ? fromValue : 0} to ${toValue} of ${totalItems.value} entries`;
});

function handlePageChange(page: number): void {
  paginationOptions.currentPage = page;
}

function resetPage(): void {
  handlePageChange(1);
}

function handleSortChange(sortObj: SortObject): void {
  paginationOptions.orderBy = resolveSortParam(sortObj);
  resetPage();
}

async function reloadTableData(): Promise<void> {
  isTableLoading.value = true;

  await Promise.allSettled([
    dataRefetch(),
    totalRefetch(),
  ]);

  isTableLoading.value = false;
}

function getOrderByAsQueryString(orderBy: SortOrderInput): string {
  if (!orderBy) return '';

  return encodeURIComponent(JSON.stringify(orderBy));
}

function getOrderByAsQueryObj(orderBy: string): unknown {
  if (!orderBy) return null;

  return JSON.parse(decodeURIComponent(orderBy));
}

watch(queryVars, async (newOptions, oldOptions) => {
  const whereWasUpdated = !isEqual(newOptions.where, oldOptions.where);
  const orderBy = getOrderByAsQueryString(paginationOptions.orderBy); // TODO: fix orderBy

  if (whereWasUpdated) {
    resetPage();
  } else {
    await update({
      ...paginationOptions,
      ...(orderBy && { orderBy }),
    });
  }
});

onDataError((error) => {
  emit('error:fetch-data', { error, result: dataResult });

  if (!props.ignoreDataErrors) {
    snackbar.danger({ messageContent: 'Failed to load the data.' });
    console.error(error);
    displayDataError.value = true;
  } else {
    displayDataError.value = false;
  }
});

onDataResult(() => {
  if (!dataError.value) {
    displayDataError.value = false;
  }
});

onTotalError((error) => {
  snackbar.danger({ messageContent: 'Failed to load the total number of items.' });
  console.error(error);
  emit('error:fetch-total', error);
});

function applyRouteQuery(): void {
  const routeQuery = get();

  // TODO: fix the paginationOptions type
  paginationOptions.itemsPerPage = routeQuery.itemsPerPage || 10;
  paginationOptions.currentPage = routeQuery.currentPage || 1;
  paginationOptions.orderBy = (
    getOrderByAsQueryObj(routeQuery.orderBy as string)
    || resolveSortParam(props.defaultSort)
  );
}

applyRouteQuery();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!dataLoaded.value || (!displayDataError.value && !_unref(totalError)))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["s-paginated-table-loading", {
          active: _unref(dataLoading) || _unref(totalLoading) || isTableLoading.value,
        }])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(SLoader), {
                loading: _unref(dataLoading) || _unref(totalLoading) || isTableLoading.value,
                height: 100,
                width: 100,
                "spinner-icon": _unref(CircularQuarterRing),
                position: "centre"
              }, null, 8, ["loading", "spinner-icon"])
            ]),
            _createVNode(_unref(STable), {
              class: _normalizeClass(__props.tableClass),
              columns: __props.columns,
              data: tableData.value,
              "sticky-header": props.stickyHeader,
              "default-sort": __props.defaultSort,
              onOnSort: handleSortChange
            }, _createSlots({ _: 2 }, [
              _renderList(__props.filters, (filter) => {
                return {
                  name: `${filter.name}_header_append`,
                  fn: _withCtx(({ itemData, rowData }) => [
                    _renderSlot(_ctx.$slots, `${filter.name}_header_append`, {
                      itemData: itemData,
                      rowData: rowData
                    })
                  ])
                }
              }),
              _renderList(__props.columns, (column) => {
                return {
                  name: `data-${column.name}`,
                  fn: _withCtx(({ itemData, rowData }) => [
                    _renderSlot(_ctx.$slots, `data-${column.name}`, {
                      itemData: itemData,
                      rowData: rowData
                    })
                  ])
                }
              })
            ]), 1032, ["class", "columns", "data", "sticky-header", "default-sort"])
          ], 2),
          (__props.pagination)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(paginationText.value), 1),
                _createVNode(_unref(SButton), {
                  type: "tertiary",
                  class: "refresh",
                  onClick: reloadTableData
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Refresh table data ")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_unref(SPagination), {
                    total: totalItems.value,
                    "per-page": paginationOptions.itemsPerPage,
                    "current-page": paginationOptions.currentPage,
                    "max-visible-buttons": 3,
                    onPagechanged: handlePageChange
                  }, null, 8, ["total", "per-page", "current-page"]),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((paginationOptions.itemsPerPage) = $event)),
                    class: "s-select"
                  }, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(availableItemsPerPageOptions, (option) => {
                      return _createElementVNode("option", { key: option }, _toDisplayString(option), 1)
                    }), 64))
                  ], 512), [
                    [
                      _vModelSelect,
                      paginationOptions.itemsPerPage,
                      void 0,
                      { number: true }
                    ]
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, " Something went wrong fetching the table data! "))
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import DOMPurify from 'dompurify';


export default /*@__PURE__*/_defineComponent({
  __name: 'SanitisedHtml',
  props: {
  html: {
    type: String,
    default: '',
  },
  tag: {
    type: String,
    default: 'div',
  },
},
  setup(__props) {

const props = __props;

const sanitisedHtml = computed(() => DOMPurify.sanitize(props.html));

return (_ctx: any,_cache: any) => {
  return _renderSlot(_ctx.$slots, "default", { sanitisedHtml: sanitisedHtml.value }, () => [
    (_openBlock(), _createBlock(_resolveDynamicComponent(__props.tag), { "inner-html": sanitisedHtml.value }, null, 8, ["inner-html"]))
  ])
}
}

})
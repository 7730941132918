<template>
  <s-popover-container class="banner">
    <div class="banner__left">
      <div>
        <div class="burger">
          <input
            type="checkbox"
            class="burger__checkbox"
            :disabled="showUserModal"
            checked
            @change="handleToggleSidebar"
          >
          <span class="burger__icon" />
          <span class="burger__icon" />
          <span class="burger__icon" />
        </div>
      </div>
      <div class="divider" />
      <div class="banner__left__branding-logo">
        <img src="../../../assets/simmons-symbol-white.png">
      </div>
      <div class="divider" />
      <div class="banner__left__drop-down">
        <product-select />
      </div>
    </div>
    <div class="banner__right">
      <s-user-avatar
        :rounded="true"
        :size="'s'"
        :fallback-background-color="userAvatarBackgroundColor"
        :user-first-name="userFirstName"
        :user-last-name="userLastName"
        @click="() => handleToggleUserModal(true)"
      />
    </div>
    <s-popover
      :is-popover-active="showUserModal"
      @toggle="handleToggleUserModal"
    >
      <div class="popover__user">
        <s-user-avatar
          :rounded="true"
          :size="'s'"
          :fallback-background-color="userAvatarBackgroundColor"
          :user-first-name="userFirstName"
          :user-last-name="userLastName"
        />
        <div class="popover__user__info">
          <h4>
            {{ user.name }}
          </h4>
          <p>
            {{ user.email }}
          </p>
        </div>
      </div>
      <div class="popover__items">
        <span @click="handleLogoutClick">
          Log out
        </span>
      </div>
    </s-popover>
  </s-popover-container>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import {
  SPopover,
  SPopoverContainer,
  SUserAvatar,
} from '@simmons/components';

import { useStore } from '@/store';
import type { AuthGetters } from '@/store/modules/auth/auth.types';

import ProductSelect from '../ProductSelect.vue';

const emit = defineEmits<{
  onSidebarOpen: [opened: boolean];
}>();

const userAvatarBackgroundColor = '#cfcf4a';
const showUserModal = ref(false);
const { useGetter, useAction } = useStore();
const user = useGetter<ReturnType<AuthGetters['user']>>('auth/user');
const [userFirstName, userLastName] = user.name.split(' ');

function handleToggleSidebar(event: Event) {
  const element = event.target as HTMLInputElement;

  emit('onSidebarOpen', element.checked);
}

function handleToggleUserModal(v?: boolean) {
  if (v !== undefined) {
    showUserModal.value = v;
  } else {
    showUserModal.value = !showUserModal.value;
  }
}

function handleLogoutClick() {
  useAction('auth/logout');
}
</script>

<style lang="scss">
@import './BrandingHeader.styles.scss';
</style>

<template>
  <s-alert
    v-if="!ranInChrome"
    class="recommended-browser-banner"
    description="
      The application is not supported in this browser
      and any changes to content may not be saved,
      please close this browser and open in Google Chrome
    "
    type="error"
  />
</template>

<script lang="ts" setup>
import { SAlert } from '@simmons/components';
import isChrome from '@/utils/isChrome';

const ranInChrome = isChrome();
</script>

<style lang="scss" scoped>
.recommended-browser-banner {
  text-align: center;
  font-weight: bold;
  color: rgb(var(--colour-white));
}
</style>

<template>
  <div>
    <s-button @click="handleExportClick">
      Export
    </s-button>
    <s-modal
      :show-modal="showDateModal"
      :width="25"
      height="auto"
      @toggle="showDateModal = false"
    >
      <template #header>
        <h3>Please select date range for export:</h3>
      </template>

      <template #default>
        <strong>Start Date:</strong>
        <s-date-picker
          v-model="fromDate"
          target="body"
          name="from"
          :validation="validation"
        />
        <strong>End Date:</strong>
        <s-date-picker
          v-model="toDate"
          target="body"
          name="to"
          :validation="validation"
        />
      </template>

      <template #footer>
        <s-button
          :loading="isLoading"
          type="primary"
          class="u-mr-sm"
          @click="handleClick"
        >
          Export
        </s-button>
        <s-button
          type="secondary"
          @click="showDateModal = false"
        >
          Cancel
        </s-button>
      </template>
    </s-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, reactive } from 'vue';
import { format } from 'date-fns';
import { ApolloError } from '@apollo/client/core';
import { SButton, SDatePicker, SModal } from '@simmons/components';

import useVuelidate from '@vuelidate/core';
import { getFilterDatesValidation } from '@/utils/validators';

import {
  SortOrder,
  type SortOrderInput,
  type StatusFieldsWhereInput,
} from '@/generated/shared-graphql';

import {
  getEndDate,
  getGraphQLErrorMessages,
  getStartDate,
} from '@/utils';
import getXlsxFromJson from '@/utils/getXlsxFromJson';
import mapDataToJSON from './mapDataToJson';

import { useLazyExportStatuses, useSnackbar } from '@/composables';

import type { FilterValueType } from '@/views/manage-status/ManageStatusTable.types';

const props = defineProps({
  filterValues: {
    type: Object as PropType<FilterValueType>,
    required: true,
  },
  appliedFilters: {
    type: Object as PropType<Record<keyof FilterValueType, boolean>>,
    required: true,
  },
  sortBy: {
    type: Object as PropType<{ [key: string]: SortOrder | SortOrderInput; } | undefined>,
    default: undefined,
  },
});

const isLoading = ref(false);

const showDateModal = ref(false);
const snackbar = useSnackbar();
const { fetchStatuses } = useLazyExportStatuses();

const emit = defineEmits<{
  'update:filterDatesValidation': [failed: boolean];
}>();

const dates = reactive<{ from: Date | null, to: Date | null; }>({
  from: null,
  to: null,
});

const rules = {
  ...getFilterDatesValidation,
};

const validation = useVuelidate(rules, dates);

const fromDate = computed({
  get: () => {
    if (dates.from !== null) {
      return dates.from;
    }

    // If dates.from is null, fallback to a default value
    return new Date();
  },
  set: (v) => {
    dates.from = v;

    emit('update:filterDatesValidation', validation.value.$invalid);
  },
});

const toDate = computed({
  get: () => {
    if (dates.to !== null) {
      return dates.to;
    }

    // If dates.to is null, fallback to a default value
    return new Date();
  },
  set: (v) => {
    dates.to = v;

    emit('update:filterDatesValidation', validation.value.$invalid);
  },
});

function getWhereStatement(): StatusFieldsWhereInput {
  const { appliedFilters, filterValues } = props;

  // Initializing the statement with fromDate filter
  let statement: StatusFieldsWhereInput = {
    date: {
      gt: fromDate.value,
    },
  };

  // Handle toDate filter
  if (toDate.value) {
    if (!statement.OR) statement.OR = [];
    statement.OR.concat({ publishDate: { lt: toDate.value } });
  }

  // Handle published filter
  if (appliedFilters.published) {
    if (filterValues.published) {
      statement = {
        ...statement,
        published: { equals: filterValues.published === 'published' },
      };
    } else {
      appliedFilters.published = false;
    }
  }

  // Handle date filter if applied
  if (appliedFilters.date) {
    if (filterValues.date) {
      statement = {
        ...statement,
        date: {
          gt: getStartDate(filterValues.date.from),
          lt: getEndDate(filterValues.date.to),
        },
      };
    } else {
      appliedFilters.date = false;
    }
  }

  // Handle publishDate filter if applied
  if (appliedFilters.publishDate) {
    if (filterValues.publishDate) {
      if (!statement.OR) statement.OR = [];
      statement.OR.concat({
        publishDate: {
          gt: getStartDate(filterValues.publishDate.from),
          lt: getEndDate(filterValues.publishDate.to),
        },
      });
    }
  }

  if (appliedFilters.jurisdiction) {
    if (filterValues.jurisdiction.length > 0) {
      statement = {
        ...statement,
        locations: {
          some: {
            id: {
              in: filterValues.jurisdiction,
            },
          },
        },
      };
    } else {
      appliedFilters.jurisdiction = false;
    }
  }

  return statement;
}

function handleExportClick(): void {
  showDateModal.value = true;
}

const handleClick = async () => {
  isLoading.value = true;

  try {
    const r = await fetchStatuses({
      where: getWhereStatement(),
      orderBy: props.sortBy || { date: { sort: SortOrder.Desc } },
    });

    if (!r?.data || r.data.statusFieldsesConnection.edges.length === 0) {
      snackbar.danger({ messageContent: 'No data found for the selected filters.' });
      isLoading.value = false;
      return;
    }

    const data = mapDataToJSON(r?.data);
    const timestamp = format(new Date(), 'dd-MM-yyyy');
    const filename = `Status export ${timestamp}.xlsx`;

    if (data) {
      getXlsxFromJson(data, filename);
    }
  } catch (e) {
    const errors = getGraphQLErrorMessages(e as ApolloError);
    errors.forEach((error) => snackbar.danger({ messageContent: error }));
  } finally {
    isLoading.value = false;
  }
};
</script>

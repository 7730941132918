<template>
  <s-input-dropdown
    v-if="is.select || is.selectMultiple"
    v-model="value"
    :options="options"
    :can-select-all="canSelectAll"
    :search="search"
    :tag-limit="tagLimit"
    size="mini"
    target="body"
    @clear="handleSelectClear"
  />
  <s-input-text
    v-else-if="is.input"
    v-model="value"
    size="small"
  />
  <div
    v-else-if="is.datepicker || is.datetimeRangePicker"
  >
    <div>
      <h4>From date</h4>
      <s-date-picker
        v-model="fromDate"
        target="body"
        name="from"
        :validation="validation"
        :show-time-picker="is.datetimeRangePicker"
      />
    </div>
    <div>
      <h4>To date</h4>
      <s-date-picker
        v-model="toDate"
        target="body"
        name="to"
        :validation="validation"
        :show-time-picker="is.datetimeRangePicker"
      />
    </div>
  </div>
  <div v-else>
    Unsupported filter type
  </div>
</template>

<script lang="ts" setup>
import { SDatePicker, SInputDropdown, SInputText } from '@simmons/components';
import type { ValidationArgs } from '@vuelidate/core';
import useVuelidate from '@vuelidate/core';
import { computed, reactive, type PropType } from 'vue';

import { getFilterDatesValidation } from '@/utils/validators';
import type { FilterType } from './FilterPanel.types';

const props = defineProps({
  modelValue: {
    type: [Array, String, Date, Object],
    default: '',
  },
  type: {
    type: String as PropType<FilterType>,
    default: 'text',
  },
  options: {
    type: Array as PropType<Array<{ label: string; value: string; }>>,
    default: () => [],
  },
  tagLimit: {
    type: Number,
    required: false,
    default: 4,
  },
  search: {
    type: Boolean,
    required: false,
    default: true,
  },
  canSelectAll: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits<{
  'update:modelValue': [value: typeof props.modelValue];
  'update:filterDatesValidation': [failed: boolean];
}>();

const is = computed(() => ({
  select: props.type === 'select',
  selectMultiple: props.type === 'select-multiple',
  datepicker: props.type === 'datepicker',
  datetimeRangePicker: props.type === 'datetime-range-picker',
  input: props.type === 'input',
}));

const dates = reactive<{ from: Date | null, to: Date | null; }>({
  from: null,
  to: null,
});

const rules: ValidationArgs = {
  ...getFilterDatesValidation,
};

const validation = useVuelidate(rules, dates);

const value = computed({
  get: () => {
    const isUndefined = props.modelValue === undefined;
    const isNull = props.modelValue === null;

    if (!(isUndefined || isNull)) {
      return props.modelValue;
    }

    if (is.value.select) {
      return undefined;
    }

    if (is.value.selectMultiple) {
      return [];
    }

    return undefined;
  },
  set: (v) => {
    emit('update:modelValue', v);
  },
});

function isObjHasProp(obj: any, prop: string) {
  return typeof obj === 'object' && obj !== null && Object.prototype.hasOwnProperty.call(obj, prop);
}

const fromDate = computed({
  get: () => {
    const isUndefined = props.modelValue === undefined;
    const isNull = props.modelValue === null;
    const hasFrom = isObjHasProp(props.modelValue, 'from');

    if (!(isUndefined || isNull) && hasFrom) {
      const { from = null } = props.modelValue as { from: Date | null, to: Date | null; };

      return from || new Date();
    }

    if (is.value.datepicker || is.value.datetimeRangePicker) {
      return new Date();
    }

    return undefined;
  },
  set: (v) => {
    dates.from = v;
    emit('update:modelValue', dates);
    emit('update:filterDatesValidation', validation.value.$invalid);
  },
});

const toDate = computed({
  get: () => {
    const isUndefined = props.modelValue === undefined;
    const isNull = props.modelValue === null;
    const hasTo = isObjHasProp(props.modelValue, 'to');

    if (!(isUndefined || isNull) && hasTo) {
      const { to = null } = props.modelValue as { from: Date | null, to: Date | null; };

      return to || new Date();
    }

    if (is.value.datepicker || is.value.datetimeRangePicker) {
      return new Date();
    }

    return undefined;
  },
  set: (v) => {
    dates.to = v;
    emit('update:modelValue', dates);
    emit('update:filterDatesValidation', validation.value.$invalid);
  },
});

function handleSelectClear() {
  value.value = undefined;
}
</script>

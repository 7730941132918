<template>
  <slot :is-owner="isOwner" />
</template>

<script lang="ts" setup>
import { toRef, type PropType } from 'vue';
import type { ValueOf } from 'ts-essentials';

import { useUserAccess } from '@/composables/';
import { ActiveProducts } from '@/config/constants';

const props = defineProps({
  product: {
    type: String as PropType<ValueOf<typeof ActiveProducts>>,
    required: true,
  },
  location: {
    type: String,
    required: true,
  },
});

const { isOwner } = useUserAccess(toRef(props, 'product'), toRef(props, 'location'));
</script>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot } from "vue"

import { toRef, type PropType } from 'vue';
import type { ValueOf } from 'ts-essentials';

import { useUserAccess } from '@/composables/';
import { ActiveProducts } from '@/config/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'OwnerOfJurisdiction',
  props: {
  product: {
    type: String as PropType<ValueOf<typeof ActiveProducts>>,
    required: true,
  },
  location: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { isOwner } = useUserAccess(toRef(props, 'product'), toRef(props, 'location'));

return (_ctx: any,_cache: any) => {
  return _renderSlot(_ctx.$slots, "default", { isOwner: _unref(isOwner) })
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../../assets/simmons-symbol-white.png'


const _hoisted_1 = { class: "banner__left" }
const _hoisted_2 = { class: "burger" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "banner__left__drop-down" }
const _hoisted_5 = { class: "banner__right" }
const _hoisted_6 = { class: "popover__user" }
const _hoisted_7 = { class: "popover__user__info" }

import { ref } from 'vue';
import {
  SPopover,
  SPopoverContainer,
  SUserAvatar,
} from '@simmons/components';

import { useStore } from '@/store';
import type { AuthGetters } from '@/store/modules/auth/auth.types';

import ProductSelect from '../ProductSelect.vue';

const userAvatarBackgroundColor = '#cfcf4a';

export default /*@__PURE__*/_defineComponent({
  __name: 'BrandingHeader',
  emits: ["onSidebarOpen"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const showUserModal = ref(false);
const { useGetter, useAction } = useStore();
const user = useGetter<ReturnType<AuthGetters['user']>>('auth/user');
const [userFirstName, userLastName] = user.name.split(' ');

function handleToggleSidebar(event: Event) {
  const element = event.target as HTMLInputElement;

  emit('onSidebarOpen', element.checked);
}

function handleToggleUserModal(v?: boolean) {
  if (v !== undefined) {
    showUserModal.value = v;
  } else {
    showUserModal.value = !showUserModal.value;
  }
}

function handleLogoutClick() {
  useAction('auth/logout');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SPopoverContainer), { class: "banner" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("input", {
              type: "checkbox",
              class: "burger__checkbox",
              disabled: showUserModal.value,
              checked: "",
              onChange: handleToggleSidebar
            }, null, 40, _hoisted_3),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "burger__icon" }, null, -1)),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "burger__icon" }, null, -1)),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "burger__icon" }, null, -1))
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "divider" }, null, -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "banner__left__branding-logo" }, [
          _createElementVNode("img", { src: _imports_0 })
        ], -1)),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "divider" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(ProductSelect)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_unref(SUserAvatar), {
          rounded: true,
          size: 's',
          "fallback-background-color": userAvatarBackgroundColor,
          "user-first-name": _unref(userFirstName),
          "user-last-name": _unref(userLastName),
          onClick: _cache[0] || (_cache[0] = () => handleToggleUserModal(true))
        }, null, 8, ["user-first-name", "user-last-name"])
      ]),
      _createVNode(_unref(SPopover), {
        "is-popover-active": showUserModal.value,
        onToggle: handleToggleUserModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(SUserAvatar), {
              rounded: true,
              size: 's',
              "fallback-background-color": userAvatarBackgroundColor,
              "user-first-name": _unref(userFirstName),
              "user-last-name": _unref(userLastName)
            }, null, 8, ["user-first-name", "user-last-name"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h4", null, _toDisplayString(_unref(user).name), 1),
              _createElementVNode("p", null, _toDisplayString(_unref(user).email), 1)
            ])
          ]),
          _createElementVNode("div", { class: "popover__items" }, [
            _createElementVNode("span", { onClick: handleLogoutClick }, " Log out ")
          ])
        ]),
        _: 1
      }, 8, ["is-popover-active"])
    ]),
    _: 1
  }))
}
}

})
<template>
  <div ref="container">
    <template v-if="!readonly">
      <div
        v-if="!isEditing"
        class="date"
      >
        <slot
          name="date"
          :date="date"
        >
          <span>
            {{ date }}
          </span>
        </slot>
        <span
          class="date-edit-button"
          title="Edit date"
        >
          <pencil-edit
            @click="handleEditClick"
          />
        </span>
      </div>
      <div
        v-else
        class="date-editor"
      >
        <s-date-picker
          v-model="newDate"
          name="date-editor"
          :available-date-func="availableDateFunc"
        />
        <div class="date-editor-end">
          <s-loader
            v-if="isSubmitting"
            class="date-editor-submitting"
            loading
            :width="16"
            :height="16"
          />
          <span
            class="date-editor-save-button"
            :class="{
              'date-editor-save-button--disabled': isSubmitting,
            }"
          >
            <save-icon @click="handleSubmit" />
          </span>
        </div>
      </div>
    </template>
    <template v-else>
      <slot
        name="date"
        :date="date"
      >
        <span>
          {{ date }}
        </span>
      </slot>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { SDatePicker, SLoader } from '@simmons/components';
import { PencilEdit, Save as SaveIcon } from '@simmons/components/icons';
import { onClickOutside } from '@vueuse/core';
import type { PropType } from 'vue';
import { ref } from 'vue';

import useSnackbar from '@/composables/useSnackbar';

const props = defineProps({
  date: {
    type: Date,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
    required: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
    required: false,
  },
  isSubmitting: {
    type: Boolean,
    default: false,
    required: false,
  },
  availableDateFunc: {
    type: Function as PropType<(date: Date) => boolean>,
    default: null,
    required: false,
  },
});

const emit = defineEmits<{
  'update:is-editing': [state: boolean];
  'on-submit': [dateValue: Date];
  'update:model-value': [dateValue: Date];
}>();

const container = ref<HTMLElement>();
const newDate = ref<Date>(props.date);

const snackbar = useSnackbar();

onClickOutside(container, () => {
  emit('update:is-editing', false);
});

function handleEditClick(): void {
  newDate.value = props.date;

  emit('update:is-editing', true);
}

function handleSubmit(): void {
  const isAvailable = props.availableDateFunc?.(newDate.value) ?? true;

  if (props.isSubmitting) {
    return;
  }

  if (!isAvailable) {
    snackbar.danger({ messageContent: "Selected date isn't available." });
    return;
  }

  emit('on-submit', newDate.value);
}
</script>

<style lang="scss" scoped>
.date-edit-button,
.date-editor-save-button {
  cursor: pointer;
  user-select: none;

  &--disabled {
    cursor: not-allowed;
  }
}

.date-editor-save-button {
  svg {
    stroke: rgb(var(--colour-black));
  }

  &--disabled {
    svg {
      stroke: rgb(var(--colour-charcoal-light-2));
    }
  }
}

.date {
  .date-edit-button {
    margin-left: .5rem;
  }
}

.date-editor {
  display: flex;
  align-items: center;

  .date-editor-end {
    margin-left: auto;
  }
}

.date-editor-end {
  position: relative;
  display: flex;
  align-items: center;

  .date-editor-submitting {
    position: absolute;
    right: 100%;
    margin-right: 0.5rem;
  }

  .date-editor-save-button {
    margin-left: 0.5rem;
  }
}
</style>

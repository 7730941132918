import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import {
  SEditor,
} from '@simmons/components';
import DOMPurify from 'dompurify';
import { computed } from 'vue';

import enableEditorDevTools from '@/utils/enableEditorDevTools';
import sanitizeEditorContent from '@/utils/sanitizeEditorContent';
import genericRichTextEditorBundle from '../../../editor/src/genericRichTextEditor/ckeditor';
import { getEditorConfig } from '../../../editor/src/genericRichTextEditor/config';


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericEditor',
  props: {
  initialValue: {
    type: String,
    default: '',
  },
},
  emits: ["on-change"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const editorConfig = computed(
  () => getEditorConfig({ initialEditorData: DOMPurify.sanitize(props.initialValue) }),
);

function setupEditorPlugins(editor: ClassicEditor): void {
  if (editor.plugins.has('TrackChangesUI')) {
    editor.execute('trackChanges');
  }
}

async function handleEditorReady(event: { id: string, editor: ClassicEditor; }) {
  const { editor } = event;

  await enableEditorDevTools(editor);

  setupEditorPlugins(editor);
}

function handleBlur(event: { id: string, editor: ClassicEditor; }) {
  const { editor } = event;
  const data = editor.getData();

  emit('on-change', sanitizeEditorContent(data));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SEditor), {
    class: "generic-editor",
    "editor-bundle": _unref(genericRichTextEditorBundle),
    config: editorConfig.value,
    onBlur: handleBlur,
    onReady: handleEditorReady
  }, null, 8, ["editor-bundle", "config"]))
}
}

})
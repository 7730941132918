<template>
  <s-modal
    v-if="!loading"
    style="text-align:center;"
    :show-modal="show"
    :width="30"
    height="auto"
    @toggle="$emit('update-show', false)"
  >
    <template #header>
      <h3>
        {{ header }}
      </h3>
    </template>
    <template
      v-if="docCyclesNumber"
    >
      <div>
        <warning />
      </div>
      <div>
        <p>
          <b>
            Warning: There are documents currently being
            processed or have failed to publish for these jurisdictions!
          </b>
        </p>
        <br>
        <u>
          Do you still want to proceed with the publication?
        </u>
      </div>
    </template>
    <template #footer>
      <div class="options">
        <s-button
          type="primary"
          class="u-mr-sm"
          @click="$emit('on-submit')"
        >
          Confirm
        </s-button>
        <s-button
          type="secondary"
          @click="$emit('on-cancel')"
        >
          Cancel
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<script lang="ts" setup>

import {
  PropType, watch, onMounted, ref,
} from 'vue';
import { SButton, SModal } from '@simmons/components';
import { Warning } from '@simmons/components/icons';
import graphqlFetch from '@/services/graphqlFetch';
import CycleDocumentsProcessingOrFailed
  from '../../views/update-cycle/graphql/query/CycleDocumentsProcessingOrFailed.gql';
import LastPublishedQuery
  from '../../views/update-cycle/graphql/query/CycleDocumentLastPublished.gql';
import type {
  LastPublishedVariables,
  LastPublishedResult,
  FailedCycleVariables,
  FailedCycleResult,
} from './confirmationModal.types';

const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  show: {
    type: Boolean,
    default: false,
  },
  cycleIds: {
    type: Array as PropType<Array<string>>,
    default: () => [],
  },
});

const docCyclesNumber = ref(0);
const compCycleIds = ref(props.cycleIds);
const loading = ref(true);

onMounted(async () => {
  if (compCycleIds.value.length) {
    const foundLastPublished = await graphqlFetch<LastPublishedResult, LastPublishedVariables>(
      LastPublishedQuery, { documentCycleIds: compCycleIds.value },
    );
    const lastPublished = foundLastPublished.data?.documentCycles?.at(0);
    const failedCycles = await graphqlFetch<FailedCycleResult, FailedCycleVariables>(
      CycleDocumentsProcessingOrFailed,
      {
        lastPublished: lastPublished?.updatedAt,
        documentId: lastPublished?.documentId ?? '0',
      },
    );

    docCyclesNumber.value = failedCycles.data?.documentCycles.length ?? 0;
    loading.value = false;
  } else {
    loading.value = false;
  }
});

const emit = defineEmits<{
  'on-cancel': [v: void];
  'on-submit': [v: void];
  'on-loaded': [v: void];
  'update-show': [value: boolean];
}>();

watch(docCyclesNumber, (after, before) => {
  if ((after !== before) && !loading.value) {
    emit('on-loaded');
  }
});
</script>

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": "2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    title: _ctx.title
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v7" }, null, -1),
    _createElementVNode("polyline", { points: "14 2 14 8 20 8" }, null, -1),
    _createElementVNode("path", { d: "m10 18 3-3-3-3" }, null, -1),
    _createElementVNode("path", { d: "M4 18v-1a2 2 0 0 1 2-2h6" }, null, -1)
  ]), 8, _hoisted_1))
}
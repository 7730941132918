<template>
  <div>
    <div
      v-if="!dataLoaded || (!displayDataError && !totalError)"
      class="s-paginated-table"
    >
      <div
        class="s-paginated-table-loading"
        :class="{
          active: dataLoading || totalLoading || isTableLoading,
        }"
      >
        <div class="s-paginated-table-loading__mask">
          <s-loader
            :loading="dataLoading || totalLoading || isTableLoading"
            :height="100"
            :width="100"
            :spinner-icon="CircularQuarterRing"
            position="centre"
          />
        </div>
        <s-table
          :class="tableClass"
          :columns="columns"
          :data="tableData"
          :sticky-header="props.stickyHeader"
          :default-sort="defaultSort"
          @on-sort="handleSortChange"
        >
          <template
            v-for="filter in filters"
            :key="`${filter.name}_header_append`"
            #[`${filter.name}_header_append`]="{ itemData, rowData }"
          >
            <slot
              :name="`${filter.name}_header_append`"
              :item-data="itemData"
              :row-data="rowData"
            />
          </template>
          <template
            v-for="column in columns"
            :key="column.name"
            #[`data-${column.name}`]="{ itemData, rowData }"
          >
            <slot
              :name="`data-${column.name}`"
              :item-data="itemData"
              :row-data="rowData"
            />
          </template>
        </s-table>
      </div>
      <div
        v-if="pagination"
        class="s-paginated-table-pagination__wrapper"
      >
        <span class="s-paginated-table-pagination__wrapper_text">
          {{ paginationText }}
        </span>
        <s-button
          type="tertiary"
          class="refresh"
          @click="reloadTableData"
        >
          Refresh table data
        </s-button>
        <div class="s-paginated-table-pagination__wrapper_right">
          <s-pagination
            :total="totalItems"
            :per-page="paginationOptions.itemsPerPage"
            :current-page="paginationOptions.currentPage"
            :max-visible-buttons="3"
            @pagechanged="handlePageChange"
          />
          <select
            v-model.number="paginationOptions.itemsPerPage"
            class="s-select"
          >
            <option
              v-for="option of availableItemsPerPageOptions"
              :key="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-else>
      Something went wrong fetching the table data!
    </div>
  </div>
</template>

<script lang="ts" setup generic="ORUF">
import { isEqual } from 'lodash-es';
import {
  computed,
  reactive,
  ref,
  watch,
  watchEffect,
  type PropType,
  type Ref,
} from 'vue';
import type { DocumentNode } from 'graphql';
import {
  SButton,
  SLoader,
  SPagination,
  STable,
} from '@simmons/components';
import { CircularQuarterRing } from '@simmons/components/icons/spinners';

import type {
  AsyncGqlTableColumn,
  AsyncTableFetchError,
  AsyncTableRefetch,
  FetchOptions,
  OptimisticRow,
  PaginationOptions,
  SortObject,
} from './AsyncGqlTable.types';

import { useProductQuery, useRouteQuery, useSnackbar } from '@/composables';
import { SortOrder, SortOrderInput } from '@/generated/shared-graphql';

const emit = defineEmits<{
  'get:tableData': [tableData: Ref<unknown>], // TODO: fix this type
  'get:tableDataRefetch': [tableDataRefetch: AsyncTableRefetch],
  'error:fetch-data': [error: AsyncTableFetchError], // TODO: fix this type
  'error:fetch-total': [error: AsyncTableFetchError], // TODO: fix this type
  'error:no-data': [error: boolean],
  'on:sort': [sortBy: string],
}>();

const props = defineProps({
  columns: {
    required: true,
    type: Array as PropType<AsyncGqlTableColumn[]>,
  },
  countQuery: {
    required: true,
    type: (Object as PropType<DocumentNode>),
  },
  countResponseMapper: {
    required: true,
    type: Function, // TODO: remove function as prop
  },
  query: {
    required: true,
    type: (Object as PropType<DocumentNode>),
  },
  responseMapper: {
    required: true,
    type: Function, // TODO: remove function as prop
  },
  defaultSort: {
    type: Object as PropType<SortObject>,
    required: false,
    default: () => ({
      key: '',
      isAscending: true,
    }),
  },
  filters: {
    default: null,
    required: false,
    type: Object,
  },
  handleSort: {
    default: null,
    required: false,
    type: (Function as PropType<(sortObj: SortObject) => { // TODO: remove function as prop
      [key: string]: SortOrder | SortOrderInput;
    }>),
  },
  queryWhere: {
    default: () => ({}),
    required: false,
    type: Object,
  },
  stickyHeader: {
    default: false,
    required: false,
    type: Boolean,
  },
  tableClass: {
    default: null,
    required: false,
    type: String,
  },
  ignoreDataErrors: {
    default: false,
    required: false,
    type: Boolean,
  },
  pagination: {
    default: true,
    required: false,
    type: Boolean,
  },
  optimisticRowUpdate: {
    required: false,
    type: Object as PropType<OptimisticRow<ORUF>>,
  },
});

const snackbar = useSnackbar();

const policy = {
  errorPolicy: 'all',
  fetchPolicy: 'cache-and-network',
};
const availableItemsPerPageOptions = [5, 10, 25, 50, 100]; // TODO: move to a prop or as config
const dataLoaded = ref(false);
const isTableLoading = ref(false);
const displayDataError = ref(!props.ignoreDataErrors ?? true);

function resolveSortParam(sortObj: SortObject): {
  [key: string]: SortOrder | SortOrderInput;
} {
  const { key, isAscending } = sortObj;

  if (props.handleSort) {
    return props.handleSort(sortObj);
  }

  const column = props.columns.find((col) => col.name === key);

  if (column?.nullOrdering?.enabled) {
    return {
      [key]: {
        sort: isAscending
          ? SortOrder.Asc : SortOrder.Desc,
        nulls: column?.nullOrdering?.position,
      },
    };
  }

  return isAscending ? { [key]: SortOrder.Asc } : { [key]: SortOrder.Desc };
}

const paginationOptions = reactive<PaginationOptions>({
  itemsPerPage: 10,
  currentPage: 1,
  orderBy: resolveSortParam(props.defaultSort),
});

const { update, get } = useRouteQuery();
const where = computed<FetchOptions['where']>(() => props.queryWhere);
const countVars = computed<FetchOptions>(() => ({
  ...where.value,
}));

const queryVars = computed<FetchOptions>(() => ({
  first: paginationOptions.itemsPerPage,
  skip: (paginationOptions.currentPage - 1) * paginationOptions.itemsPerPage,
  orderBy: paginationOptions.orderBy,
  ...countVars.value,
}));

const {
  result: dataResult,
  loading: dataLoading,
  error: dataError,
  refetch: dataRefetch,
  onError: onDataError,
  onResult: onDataResult,
} = useProductQuery(
  props.query,
  queryVars,
  policy,
);

const {
  result: totalResult,
  loading: totalLoading,
  error: totalError,
  refetch: totalRefetch,
  onError: onTotalError,
} = useProductQuery(
  props.countQuery,
  countVars,
  policy,
);

const tableData = computed(() => (props.responseMapper(dataResult.value)));
const totalItems = computed((): number => (props.countResponseMapper(totalResult.value)));

watch(() => props.optimisticRowUpdate, (row, prev) => {
  // find the row in the table data and update it
  if (row && (!prev || row.value !== prev.value)) {
    const index = tableData.value.findIndex((r: any) => r.id === row.id);

    if (index !== -1) {
      tableData.value[index][row.field] = row.value;
    }
  }
});

watchEffect(() => {
  if (paginationOptions.orderBy) {
    const orderByKey = Object.keys(paginationOptions.orderBy)[0] || '';

    if (orderByKey) emit('on:sort', orderByKey);
  }
});

// watch the total items and emit an event related to whether or not there are any items
watchEffect(() => {
  const total = totalItems.value;

  emit('error:no-data', (total === 0));
});

watch(dataLoading, (isLoading: boolean) => {
  if (!isLoading) {
    emit('get:tableData', tableData);
    emit('get:tableDataRefetch', dataRefetch);
    dataLoaded.value = true;
  }
});

const paginationText = computed(() => {
  const skip = queryVars.value.skip || 0;
  const fromValue = (skip + 1) < totalItems.value
    ? skip + 1
    : totalItems.value;
  const toValue = (skip + tableData.value.length) < totalItems.value
    ? skip + tableData.value.length
    : totalItems.value;

  return `Showing ${tableData.value.length ? fromValue : 0} to ${toValue} of ${totalItems.value} entries`;
});

function handlePageChange(page: number): void {
  paginationOptions.currentPage = page;
}

function resetPage(): void {
  handlePageChange(1);
}

function handleSortChange(sortObj: SortObject): void {
  paginationOptions.orderBy = resolveSortParam(sortObj);
  resetPage();
}

async function reloadTableData(): Promise<void> {
  isTableLoading.value = true;

  await Promise.allSettled([
    dataRefetch(),
    totalRefetch(),
  ]);

  isTableLoading.value = false;
}

function getOrderByAsQueryString(orderBy: SortOrderInput): string {
  if (!orderBy) return '';

  return encodeURIComponent(JSON.stringify(orderBy));
}

function getOrderByAsQueryObj(orderBy: string): unknown {
  if (!orderBy) return null;

  return JSON.parse(decodeURIComponent(orderBy));
}

watch(queryVars, async (newOptions, oldOptions) => {
  const whereWasUpdated = !isEqual(newOptions.where, oldOptions.where);
  const orderBy = getOrderByAsQueryString(paginationOptions.orderBy); // TODO: fix orderBy

  if (whereWasUpdated) {
    resetPage();
  } else {
    await update({
      ...paginationOptions,
      ...(orderBy && { orderBy }),
    });
  }
});

onDataError((error) => {
  emit('error:fetch-data', { error, result: dataResult });

  if (!props.ignoreDataErrors) {
    snackbar.danger({ messageContent: 'Failed to load the data.' });
    console.error(error);
    displayDataError.value = true;
  } else {
    displayDataError.value = false;
  }
});

onDataResult(() => {
  if (!dataError.value) {
    displayDataError.value = false;
  }
});

onTotalError((error) => {
  snackbar.danger({ messageContent: 'Failed to load the total number of items.' });
  console.error(error);
  emit('error:fetch-total', error);
});

function applyRouteQuery(): void {
  const routeQuery = get();

  // TODO: fix the paginationOptions type
  paginationOptions.itemsPerPage = routeQuery.itemsPerPage || 10;
  paginationOptions.currentPage = routeQuery.currentPage || 1;
  paginationOptions.orderBy = (
    getOrderByAsQueryObj(routeQuery.orderBy as string)
    || resolveSortParam(props.defaultSort)
  );
}

applyRouteQuery();
</script>

<style lang="scss">
@import "./AsyncGqlTable.styles.scss";
</style>

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "s-tooltip" }

import type { PropType } from 'vue';

import { isInArray } from '../../utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'STooltip',
  props: {
  position: {
    type: String as PropType<'right' | 'left' | 'top' | 'bottom'>,
    default: 'right',
    validator: (v: string) => isInArray(v, ['right', 'left', 'top', 'bottom']),
    required: false,
  },
  dark: {
    type: Boolean,
    default: false,
    required: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("span", {
      class: _normalizeClass(["s-tooltip__text", {
        's-tooltip--right': __props.position === 'right',
        's-tooltip--left': __props.position === 'left',
        's-tooltip--top': __props.position === 'top',
        's-tooltip--bottom': __props.position === 'bottom',
        's-tooltip--dark': __props.dark,
      }])
    }, [
      _renderSlot(_ctx.$slots, "tooltip")
    ], 2)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = { key: 3 }

import { SDatePicker, SInputDropdown, SInputText } from '@simmons/components';
import type { ValidationArgs } from '@vuelidate/core';
import useVuelidate from '@vuelidate/core';
import { computed, reactive, type PropType } from 'vue';

import { getFilterDatesValidation } from '@/utils/validators';
import type { FilterType } from './FilterPanel.types';


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterPanel',
  props: {
  modelValue: {
    type: [Array, String, Date, Object],
    default: '',
  },
  type: {
    type: String as PropType<FilterType>,
    default: 'text',
  },
  options: {
    type: Array as PropType<Array<{ label: string; value: string; }>>,
    default: () => [],
  },
  tagLimit: {
    type: Number,
    required: false,
    default: 4,
  },
  search: {
    type: Boolean,
    required: false,
    default: true,
  },
  canSelectAll: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  emits: ["update:modelValue", "update:filterDatesValidation"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const is = computed(() => ({
  select: props.type === 'select',
  selectMultiple: props.type === 'select-multiple',
  datepicker: props.type === 'datepicker',
  datetimeRangePicker: props.type === 'datetime-range-picker',
  input: props.type === 'input',
}));

const dates = reactive<{ from: Date | null, to: Date | null; }>({
  from: null,
  to: null,
});

const rules: ValidationArgs = {
  ...getFilterDatesValidation,
};

const validation = useVuelidate(rules, dates);

const value = computed({
  get: () => {
    const isUndefined = props.modelValue === undefined;
    const isNull = props.modelValue === null;

    if (!(isUndefined || isNull)) {
      return props.modelValue;
    }

    if (is.value.select) {
      return undefined;
    }

    if (is.value.selectMultiple) {
      return [];
    }

    return undefined;
  },
  set: (v) => {
    emit('update:modelValue', v);
  },
});

function isObjHasProp(obj: any, prop: string) {
  return typeof obj === 'object' && obj !== null && Object.prototype.hasOwnProperty.call(obj, prop);
}

const fromDate = computed({
  get: () => {
    const isUndefined = props.modelValue === undefined;
    const isNull = props.modelValue === null;
    const hasFrom = isObjHasProp(props.modelValue, 'from');

    if (!(isUndefined || isNull) && hasFrom) {
      const { from = null } = props.modelValue as { from: Date | null, to: Date | null; };

      return from || new Date();
    }

    if (is.value.datepicker || is.value.datetimeRangePicker) {
      return new Date();
    }

    return undefined;
  },
  set: (v) => {
    dates.from = v;
    emit('update:modelValue', dates);
    emit('update:filterDatesValidation', validation.value.$invalid);
  },
});

const toDate = computed({
  get: () => {
    const isUndefined = props.modelValue === undefined;
    const isNull = props.modelValue === null;
    const hasTo = isObjHasProp(props.modelValue, 'to');

    if (!(isUndefined || isNull) && hasTo) {
      const { to = null } = props.modelValue as { from: Date | null, to: Date | null; };

      return to || new Date();
    }

    if (is.value.datepicker || is.value.datetimeRangePicker) {
      return new Date();
    }

    return undefined;
  },
  set: (v) => {
    dates.to = v;
    emit('update:modelValue', dates);
    emit('update:filterDatesValidation', validation.value.$invalid);
  },
});

function handleSelectClear() {
  value.value = undefined;
}

return (_ctx: any,_cache: any) => {
  return (is.value.select || is.value.selectMultiple)
    ? (_openBlock(), _createBlock(_unref(SInputDropdown), {
        key: 0,
        modelValue: value.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        options: __props.options,
        "can-select-all": __props.canSelectAll,
        search: __props.search,
        "tag-limit": __props.tagLimit,
        size: "mini",
        target: "body",
        onClear: handleSelectClear
      }, null, 8, ["modelValue", "options", "can-select-all", "search", "tag-limit"]))
    : (is.value.input)
      ? (_openBlock(), _createBlock(_unref(SInputText), {
          key: 1,
          modelValue: value.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((value).value = $event)),
          size: "small"
        }, null, 8, ["modelValue"]))
      : (is.value.datepicker || is.value.datetimeRangePicker)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _cache[4] || (_cache[4] = _createElementVNode("h4", null, "From date", -1)),
              _createVNode(_unref(SDatePicker), {
                modelValue: fromDate.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((fromDate).value = $event)),
                target: "body",
                name: "from",
                validation: _unref(validation),
                "show-time-picker": is.value.datetimeRangePicker
              }, null, 8, ["modelValue", "validation", "show-time-picker"])
            ]),
            _createElementVNode("div", null, [
              _cache[5] || (_cache[5] = _createElementVNode("h4", null, "To date", -1)),
              _createVNode(_unref(SDatePicker), {
                modelValue: toDate.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((toDate).value = $event)),
                target: "body",
                name: "to",
                validation: _unref(validation),
                "show-time-picker": is.value.datetimeRangePicker
              }, null, 8, ["modelValue", "validation", "show-time-picker"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, " Unsupported filter type "))
}
}

})
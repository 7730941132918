import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, watchEffect, type Ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  SInputDropdown,
} from '@simmons/components';

import {
  APP__SET_SELECTED_PRODUCT,
  APP__SET_CLIENT_ENDPOINT,
} from '@/store/modules/app/mutations';
import { LOCAL_STORAGE_SELECTED_PRODUCT_KEY } from '@/config/constants';
import type { ActiveProduct } from '@/config';

import { getRouteNames, isActiveProduct } from '@/utils';
import { setItemInLocalStorage } from '@/utils/localStorage';
import { setItemInState } from '@/utils/vuexState';

import useUserAccess from '@/composables/useUserAccess';
import useSelectedProduct from '@/composables/useSelectedProduct';
import type { PrimitiveValue, PrimitiveValueVec } from '@/types/utilityTypes';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductSelect',
  setup(__props) {

const router = useRouter();

const {
  selectedProduct: currentSelectedProduct,
  routeProduct,
  productEndpoint,
} = useSelectedProduct();

const { productsUserCanAccess = [] } = (currentSelectedProduct.value !== null)
  ? useUserAccess(currentSelectedProduct as Ref<ActiveProduct>)
  : {};

const currentlySelectedProduct = computed(
  (): ActiveProduct | undefined => {
    const value = currentSelectedProduct.value || routeProduct.value;

    if (value) {
      return value;
    }

    return undefined;
  },
);
const availableProducts = computed(
  () => productsUserCanAccess.filter(
    ({ value }) => isActiveProduct(value),
  ),
);

function setSelectedProductInState(selectedProduct: string) {
  setItemInState<string>(APP__SET_SELECTED_PRODUCT, selectedProduct);
}

function setSelectedProductInLocalStorage(selectedProduct: ActiveProduct) {
  setItemInLocalStorage<string>(
    LOCAL_STORAGE_SELECTED_PRODUCT_KEY,
    selectedProduct,
  );
}

watchEffect(() => {
  const product = currentlySelectedProduct.value;

  if (product && currentSelectedProduct.value !== product) {
    setSelectedProductInState(product);
    setSelectedProductInLocalStorage(product);
  }
});

const stopSetClientState = watchEffect(() => {
  setItemInState<string>(APP__SET_CLIENT_ENDPOINT, productEndpoint.value);
});

function pushToRoute(routeName: string) {
  router.push({
    name: routeName,
  });
}

function handleChangeProduct(productValue: PrimitiveValue | PrimitiveValueVec | null) {
  stopSetClientState();

  if (productValue && typeof productValue === 'string' && isActiveProduct(productValue)) {
    const routeNames = getRouteNames(router.getRoutes());
    const existingProductRoute = routeNames.find(
      (routeName) => (routeName === productValue),
    );

    setSelectedProductInState(productValue);
    setSelectedProductInLocalStorage(productValue);

    if (existingProductRoute) {
      pushToRoute(existingProductRoute);
      setItemInState<string>(APP__SET_CLIENT_ENDPOINT, productEndpoint.value);
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SInputDropdown), {
    class: "product__dropdown",
    "dark-bg": true,
    "model-value": currentlySelectedProduct.value,
    options: availableProducts.value,
    "onUpdate:modelValue": handleChangeProduct
  }, null, 8, ["model-value", "options"]))
}
}

})
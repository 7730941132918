import type { Primitive } from 'ts-essentials';
import type { Key } from '@/types/utilityTypes';

/**
 *
 * @param obj The object to find the key that has a value matching the given value
 * @param value The primitive value to search for within the object
 *
 * @returns The key that has a value matching the given value or undefined
 */
export function findKeyFromValue<T extends Record<Key, Primitive>>(obj: T, value: Primitive) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

export function findKeyFromStrValue<T extends Record<Key, string>>(obj: T, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

export default findKeyFromValue;

<template>
  <div class="publish-options-panel u-mt-sm u-mb-sm">
    <div
      v-if="showInlineOptions"
      class="publish-options-panel__options-buttons u-mb-sm u-mt-sm"
    >
      <h2>Publish Options</h2>
      <p>
        <!-- User-friendly message for number of selected rows / entries -->
        {{ numEntries ? `${numEntries} Row${numEntries >= 2 ? 's' : ''} selected` : '' }}
      </p>
      <s-button
        class="primary"
        @click="$emit('statuses:publish')"
      >
        Publish Now
      </s-button>
      <s-button
        class="u-mr-sm u-ml-sm"
        @click="$emit('edit-publish-date-panel:toggle')"
      >
        Edit Publish Date
      </s-button>
      <s-button
        @click="$emit('statuses:clear-publish-date')"
      >
        Clear Publish Date
      </s-button>
    </div>
    <s-checkbox
      :model-value="areAllEntriesSelected"
      :disabled="!canToggleSelectionOfAllStatuses"
      class="publish-options-panel__toggle-entries-selection-checkbox"
      label="Select (visible)"
      @update:model-value="handleStatusesSelection"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import {
  SCheckbox, SButton,
} from '@simmons/components';

const emit = defineEmits<{
  'statuses:select-all-toggle': [checked: boolean];
  'statuses:publish': [v: void];
  'statuses:clear-publish-date': [v: void];
  'edit-publish-date-panel:toggle': [v: void];
}>();

const props = defineProps({
  numEntries: {
    type: Number,
    default: 0,
  },
  areAllEntriesSelected: {
    type: Boolean,
    default: false,
  },
  canToggleSelectionOfAllStatuses: {
    type: Boolean,
    default: false,
  },
});

const showInlineOptions = computed(
  () => props.numEntries > 0 && props.canToggleSelectionOfAllStatuses,
);

function handleStatusesSelection(checked: boolean): void {
  emit('statuses:select-all-toggle', checked);
}
</script>

<style lang="scss">
.publish-options-panel__toggle-entries-selection-checkbox.checkbox-wrapper {
  display: flex;
  align-items: center;

  label.checkbox-label {
    margin-left: .75rem;
    font-weight: 600;
  }
}
</style>

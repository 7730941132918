import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "publish-options-panel u-mt-sm u-mb-sm" }
const _hoisted_2 = {
  key: 0,
  class: "publish-options-panel__options-buttons u-mb-sm u-mt-sm"
}

import { computed } from 'vue';
import {
  SCheckbox, SButton,
} from '@simmons/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'PublishOptionsPanel',
  props: {
  numEntries: {
    type: Number,
    default: 0,
  },
  areAllEntriesSelected: {
    type: Boolean,
    default: false,
  },
  canToggleSelectionOfAllStatuses: {
    type: Boolean,
    default: false,
  },
},
  emits: ["statuses:select-all-toggle", "statuses:publish", "statuses:clear-publish-date", "edit-publish-date-panel:toggle"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

const showInlineOptions = computed(
  () => props.numEntries > 0 && props.canToggleSelectionOfAllStatuses,
);

function handleStatusesSelection(checked: boolean): void {
  emit('statuses:select-all-toggle', checked);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showInlineOptions.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Publish Options", -1)),
          _createElementVNode("p", null, _toDisplayString(__props.numEntries ? `${__props.numEntries} Row${__props.numEntries >= 2 ? 's' : ''} selected` : ''), 1),
          _createVNode(_unref(SButton), {
            class: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('statuses:publish')))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Publish Now ")
            ])),
            _: 1
          }),
          _createVNode(_unref(SButton), {
            class: "u-mr-sm u-ml-sm",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('edit-publish-date-panel:toggle')))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Edit Publish Date ")
            ])),
            _: 1
          }),
          _createVNode(_unref(SButton), {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('statuses:clear-publish-date')))
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Clear Publish Date ")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(SCheckbox), {
      "model-value": __props.areAllEntriesSelected,
      disabled: !__props.canToggleSelectionOfAllStatuses,
      class: "publish-options-panel__toggle-entries-selection-checkbox",
      label: "Select (visible)",
      "onUpdate:modelValue": handleStatusesSelection
    }, null, 8, ["model-value", "disabled"])
  ]))
}
}

})
// CKEditor Plugins
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FindAndReplace from "@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js";
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

class CKEditorCS extends ClassicEditor { }
// Plugins to include in the build.
CKEditorCS.builtinPlugins = [
  Autosave,
  Bold,
  FindAndReplace,
  ItalicPlugin,
  UnderlinePlugin,
  StrikethroughPlugin,
  Essentials,
  Highlight,
  ListPlugin,
  Link,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  WordCount,
];

export default CKEditorCS;

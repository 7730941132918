<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    :title="title"
  >
    <path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v7" />
    <polyline points="14 2 14 8 20 8" />
    <path d="m10 18 3-3-3-3" />
    <path d="M4 18v-1a2 2 0 0 1 2-2h6" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SupportingDocsIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Supporting docs icon',
    },
  },
});
</script>

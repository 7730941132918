import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ppp-error-panel" }
const _hoisted_2 = { class: "ppp-error-panel__title" }
const _hoisted_3 = { class: "ppp-error-panel__error-list" }

import type { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorPanel',
  props: {
  errors: {
    type: Array as PropType<Error[]>,
    require: false,
    default: () => [],
  },
  title: {
    type: String,
    require: false,
    default: 'Errors',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errors, (error) => {
        return (_openBlock(), _createElementBlock("li", {
          key: error.name,
          class: "ppp-error-panel__message"
        }, _toDisplayString(error.message), 1))
      }), 128))
    ])
  ]))
}
}

})
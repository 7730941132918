<template>
  <cancel-icon
    v-bind="$attrs"
    class="unpublish-button"
    :class="{
      'unpublish-button--disabled': disabled,
    }"
    width="18"
    height="18"
    role="button"
    title="Unpublish"
    @click="handleUnpublishButtonClick"
  />
  <div
    v-if="loading"
    class="unpublish-loader-container"
  >
    <s-loader
      class="unpublish-loader"
      loading
      :height="16"
    />
  </div>
  <s-modal
    v-if="showModal"
    show-modal
    width="auto"
    height="auto"
    target="body"
    @toggle="handleModalToggle"
  >
    <template #header>
      <h2 class="modal-header">
        {{ headerContent }}
      </h2>
    </template>
    <template #footer>
      <div class="modal-footer">
        <s-button
          type="primary"
          @click="handleSubmit"
        >
          {{ actionButtonMessage }}
        </s-button>
        <s-button
          type="secondary"
          @click="handleCancel"
        >
          {{ cancelButtonMessage }}
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<script lang="ts" setup>
import { SButton, SLoader, SModal } from '@simmons/components';
import { Cancel as CancelIcon } from '@simmons/components/icons';
import { ref } from 'vue';

const props = defineProps({
  headerContent: {
    type: String,
    required: true,
  },
  actionButtonMessage: {
    type: String,
    default: 'Yes, unpublish now',
  },
  cancelButtonMessage: {
    type: String,
    default: 'Cancel',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  'on-submit': [v: void];
  'on-cancel': [v: void];
}>();

const showModal = ref(false);

function closeModal() {
  showModal.value = false;
}

function handleUnpublishButtonClick() {
  if (!props.disabled) {
    showModal.value = true;
  }
}

function handleModalToggle() {
  showModal.value = !showModal.value;
}

function handleCancel() {
  closeModal();

  emit('on-cancel');
}

function handleSubmit() {
  closeModal();

  emit('on-submit');
}
</script>

<style lang="scss" scoped>
@import './UnpublishButton.styles.scss';
</style>
